
import { Vue, Component, ModelSync } from "vue-property-decorator";

@Component
export default class CalendarRangeInput extends Vue {
    @ModelSync("dateValue", "change", { type: Array })
    readonly dates?: string[];

    get formatDates() {
        if (this.dates) {
            return this.dates.join(" ~ ");
        }
        return "";
    }
}
